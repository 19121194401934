'use client';

import { RecommendationContext } from '@/configs/recommendations';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useRecommendedProducts } from '@/react/hooks/useRecommendedProducts';
import { isNullOrEmpty } from '@/utils/null-utils';
import { observer } from 'mobx-react-lite';
import ProductTile from '../../ProductTile';

// Import styles and assets
import S from './styles.module.scss';

export interface IRecommendationsProps {
  /**
   * The recommendation context to render.
   */
  context: RecommendationContext;
}

const DEFAULT_VARIANT = 'recommendation';
const DEFAULT_COUNT = 4;

/** Component that displays product recommendations.  */
const Recommendations = observer(function Recommendations({
  context
}: IRecommendationsProps) {
  const { products, title, isRecommendationEnabled, isSlideOnMobile } =
    useRecommendedProducts(context);

  const variant = DEFAULT_VARIANT;

  if (!isRecommendationEnabled) return null;

  return (
    <div className={classes(S.container, isSlideOnMobile ? S.slide : S.grid)}>
      {!isNullOrEmpty(title) && <div className={S.title}>{title}</div>}
      <div className={S.tilesContainer}>
        {products?.slice(0, DEFAULT_COUNT).map((product) => {
          return (
            <div className={S.tileContainer} key={product.sku}>
              <ProductTile
                product={product}
                variant={variant}
                isSlideTile={isSlideOnMobile}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default Recommendations;
